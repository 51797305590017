/* eslint-disable camelcase */
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import posthog from 'posthog-js';
import { filter, pairwise, Subject } from 'rxjs';

import { ANALYTICS, Analytics, EventIdentifier } from '@smw/analytics-front';
import { JobResponsibility } from '@smw/common-models';
import { isNavigationEnd } from '@smw/navigation-front';
import { JourneyStore, SpaceStore, UserStore } from '@smw/stores-front';
import { NotificationService } from '@smw/ui-front';
import { AppConfigService, showSentryFeedback } from '@smw/utils-front';

@Component({
  selector: 'smw-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(ANALYTICS) private analytics: Analytics,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DOCUMENT) document: Document,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private hotkeysService: HotkeysService,
    private notification: NotificationService,
    private userStore: UserStore,
    private spaceStore: SpaceStore,
    private configService: AppConfigService,
    private journey: JourneyStore,
  ) {
    this.journey.loadJourney().subscribe();
    document.documentElement.lang = locale;
    const config = this.configService.getConfig();
    if (!config) {
      return;
    }

    const envName = this.configService.getConfig()?.envName;

    // We prefer using that technic because Sentry recommands initing the lib directly in main.ts file, but the app config is not available then and there.
    // So we keep it like to to have early exception monitoring + correct env name after config load
    Sentry.withScope((scope) =>
      scope.addEventProcessor((event) => ({
        ...event,
        environment: envName,
      })),
    );

    posthog.init(config.posthogProjectKey, {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'always',
      capture_pageview: false,
      capture_pageleave: true,
      enable_recording_console_log: true,
      enable_heatmaps: true,
      property_denylist: ['password'],
    });

    if (envName !== 'production') {
      posthog.debug(true);
    } else {
      // Necessary because the settings are persisted in local storage
      posthog.debug(false);
    }
  }

  private clickTimes: number[] = [];
  private RAGE_CLICK_THRESHOLD = 5; // Number of clicks
  private RAGE_CLICK_TIMEFRAME = 1000; // Milliseconds

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent): void {
    const now = Date.now();
    this.clickTimes.push(now);

    // Keep only recent clicks within the timeframe
    this.clickTimes = this.clickTimes.filter(
      (timestamp) => now - timestamp < this.RAGE_CLICK_TIMEFRAME,
    );

    if (this.clickTimes.length >= this.RAGE_CLICK_THRESHOLD) {
      this.clickTimes = []; // Reset after detection
      console.warn('Rage click detected! Showing internal report dialog', event.target);
      showSentryFeedback();
    }
  }
  identifyUser = effect(() => {
    const user = this.userStore.user();
    if (!user) {
      return;
    }

    this.analytics.identify(user.id, {
      email: user.email,
      tenant: user.company?.subDomain ?? '',
      roles: ['user'],
      firstname: user.firstname,
      lastname: user.lastname ?? '',
      companyBusinessService: user.businessServiceId ?? '',
      jobResponsabilities: user.jobResponsabilities ?? JobResponsibility.OTHER,
      newsletterConsent: user.newsletterConsent ?? false,
      clientId: user.company?.id ?? '',
      clientName: user.company?.name ?? '',
      clientActive: user.company?.active ?? true,
      isRealUser: user.isRealUser ?? true,
    });

    Sentry.setUser({ username: user.firstname, email: user.email, id: user.id });
    // We could register a real analytics service for userback but since we only need the idenfity its a bit overkill
    // Also there's a typescript SDK, but similarly it's a bit overkill and make it rely on the app initialisation, while pure html integration make the feedback tool usable even if the app can't bootstrap // there's a code issue on in our stack.
    // Also, we will likely stop using userback very soon
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userBack = (<any>window).Userback;
    userBack?.identify?.(user.id, {
      name: this.userStore.fullname(),
      email: user.email,
      account_id: user.company.id,
    });
  });

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.setupI18n();
    this.trackNavigationEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setupI18n(): void {
    this.translateService.addLangs(['fr', 'key']);

    this.hotkeysService.add(
      new Hotkey('ctrl+alt+l', (): boolean => {
        if (this.translateService.currentLang === 'key') {
          this.translateService.use('fr');
        } else {
          this.notification.info({
            message: `Appuyez sur Ctrl + Alt + L pour afficher les textes.`,
          });
          this.translateService.use('key');
        }

        return false; // Prevent bubbling
      }),
    );
    this.changeDetectorRef.markForCheck();
  }

  // TODO : REWORK THAT super important, seems we are missing the first page view when loading the app, as well as the page exit when closing or reloading the page
  private trackNavigationEvents(): void {
    this.router.events
      .pipe(filter(isNavigationEnd), pairwise())
      .subscribe((event: NavigationEnd[]) => {
        const previousNavigation = event[0];
        const currentNavigation = event[1];

        if (previousNavigation) {
          this.analytics.track({
            id: EventIdentifier.PageClosed,
            data: { url: previousNavigation.url },
          });
        }
        this.analytics.startTimer(EventIdentifier.PageClosed);

        this.analytics.track({
          id: EventIdentifier.PageOpened,
          data: {
            url: currentNavigation.url,
            previousUrl: previousNavigation.url,
          },
        });
      });
  }
}
